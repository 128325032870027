import React from 'react';
import Map from './Map.js';

function MapCaption() {
  return (
    <Map 
      src="/assets/maps/usa.svg"
      alt="Covid-19 chloropleth map"
      href="https://www.nbcnews.com/health/health-news/coronavirus-deaths-u-s-map-shows-number-fatalities-compared-confirmed-n1166966"
      callToAction="VIEW MAP"
    />
  );
}

export default MapCaption;