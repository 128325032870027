import React, { useState, useEffect } from 'react';
import styles from './Stocks.module.scss';
import useInterval from '../hooks/useInterval';
import ExternalLink from './ExternalLink';

function Stock() {
  const [ stocks, setStocks ] = useState([]);

  async function fetchStocks() {
    const response = await fetch(`${process.env.REACT_APP_S3_BUCKET_HOST}/data/market.json`);
    const { quotes } = await response.json();

    return quotes;
  }

  useEffect(() => {
    async function initialize() {
      const stocks = await fetchStocks();

      setStocks(stocks);
    }

    initialize();
  }, []);

  useInterval(async () => {
    const stocks = await fetchStocks();

    setStocks(stocks);
  }, 30000);

  const percentClassMap = {
    '1': styles.up,
    '-1': styles.down,
  }

  const getTrend = (percent) => {
    const output = Math.sign(percent);
    return percentClassMap[output] || '';
  }

  return (
    <section className={styles.stocks}>
      <table className={styles.table}>
        <tbody>
          {stocks.map(stock => {
            const { shortName: symbol, last: total, link: href, changePercent: percent } = stock;
            return (
              <tr key={symbol} data-testid="stock">
                <td className={styles.symbol} data-testid="symbol">
                  <ExternalLink className={styles.link} href={href}>
                    {symbol}
                  </ExternalLink>
                </td>
                <td className={styles.position} data-testid="position">
                  <ExternalLink className={styles.link} href={href}>
                    {total.toLocaleString()}
                  </ExternalLink>
                </td>
                <td className={`${styles.percent} ${getTrend(percent)}`} data-testid="percent">
                  <ExternalLink className={styles.link} href={href}>
                    <span className={styles.percentText} data-testid="percentText">
                      {percent}%
                    </span>
                  </ExternalLink>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  );
}

export default Stock;
