import { useEffect, useState } from 'react';
import getEndpointNational from '../utils/getEndpointNational';
import getEndpointLocalData from '../utils/getEndpointLocalData';

/*
  General note -- when local data is not available fallback to national
*/
const useFetchLocations = (national, local) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (national && local) {
          const { endpoint: endpointLocal, state } = await getEndpointLocalData();

          const urls = [
            getEndpointNational(),
            endpointLocal
          ];

          const responses = await Promise.all(urls.map(url => url && fetch(url)));
          const [ national, local ] = await Promise.all(responses.map(response => response && response.json()));

          setResponse({ 
            national, 
            local: {
              ...local,
              state
            }
          });
        } else if (!national && local) {
          const { endpoint: endpointLocal, state } = await getEndpointLocalData();

          let response;
          if (endpointLocal) {
            response = await fetch(endpointLocal);
          }
          
          let local;
          let national;
          if (response && response.ok) {
            local = await response.json();
          } else {
            local = { 
              cases: null,
              deaths: null
            };
            const nationalresponse = await fetch(getEndpointNational());
            national = await nationalresponse.json();
          }

          setResponse({ 
            national: national || {}, 
            local: {
              ...local,
              state
            }
          });
        } else if (national && !local) {
          const response = await fetch(getEndpointNational());
          const national = response.ok ? await response.json() : {};

          setResponse({ 
            national, 
            local: {}
          });
        }
      } catch (error) {
        console.log(error);
        setError(error);
      }
    };
    fetchData();
  }, [national, local]);
  
  return { response, error };
};

export default useFetchLocations;