import React from 'react';
import Map from './Map.js';

function MapBasic({ national, global, ...overrides }) {
  return (
    <>
      {(national && !global) && <Map
        src="https://dataviz.nbcnews.com/projects/20200128-coronavirus-us-count/assets/covid-marquee.png"
        alt="Covid-19 national chloropleth map"
        href="https://www.nbcnews.com/health/health-news/coronavirus-deaths-u-s-map-shows-number-fatalities-compared-confirmed-n1166966"
        {...overrides}
      />}

      {(!national && global) && <Map
        src="https://dataviz.nbcnews.com/projects/20200122-coronavirus-world-count/assets/covid-marquee.png"
        alt="Covid-19 global chloropleth map"
        href="https://www.nbcnews.com/health/health-news/coronavirus-deaths-u-s-map-shows-number-fatalities-compared-confirmed-n1166966"
        {...overrides}
      />}
    </>
  );
}

export default MapBasic;