import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';

import MarqueePage from './components/MarqueePage';
import MarqueeDemoPage from './components/MarqueeDemoPage';

const router = (
  <BrowserRouter>
      <Switch>
      <Route path="/demo">
          <MarqueeDemoPage />
        </Route>
        <Route path="*">
          <MarqueePage />
        </Route>
      </Switch>
  </BrowserRouter>
);

export default router;

