import React from 'react';
import styles from './Ticker.module.scss';

function Ticker(props) {
  const { ticker, tickerWrapper } = styles;

  return (
    <section className={tickerWrapper}>
      <div className={ticker} data-total-items={props.children.length}>
        {props.children}
      </div>
    </section>
  );
}

export default Ticker;
