import React from 'react';
import styles from './Divider.module.scss';

function Divider({ dashed }) {
  return (
    <div className={`divider ${styles.divider}`}>
      <div className={`${styles.spacer} ${dashed ? styles.dashed : ''}`} />
    </div>
  )
}

export default Divider;