import { useLocation } from "react-router-dom";
import pymParams from '../utils/getPymQueryParams';


function useURLSearchParams() {
  const params = new URLSearchParams(useLocation().search);
  const nonPymParams = Array.from(params).filter(([name]) => !pymParams.includes(name));
  return nonPymParams;
}

export default useURLSearchParams;