import React from 'react';
import Footer from './Footer';

function FooterBasic({ ...overrides }) {
  return (
    <Footer
      liveBlogText="Live Blog"
      liveBlogHref="https://www.nbcnews.com"
      headlineText="Full coverage"
      headlineHref="https://www.nbcnews.com"
      { ...overrides }
    />
  );
}

export default FooterBasic;