import React from 'react';
import styles from './Figures.module.scss';

function Figures({ figures }) {
  const maxNumber = Math.max(...figures.map(({ number }) => number)).toLocaleString();
  const longestCaption = [...figures].sort((a, b) => a.caption.length - b.caption.length).pop().caption;

  return (
    <section className={styles.section}>
      {figures.map(({ header, number, numberColor, percent, caption }, i) => {
        return (
          <figure key={i + number + caption} className={styles.figure}>
            {header && (
              <header className={styles.header}>
                {header}
              </header>
            )}
      
            <div className={styles.dataContainer}>
              <data 
                className={styles.number} 
                value={number}
                data-max-number={maxNumber}
                style={{ color: numberColor }}
              >
                <span className={styles.span}>{number.toLocaleString()}</span>
              </data>
              
              <figcaption 
                className={styles.figcaption} 
                data-longest-caption={longestCaption}
                data-percent={percent && `+${percent}%`}
              >
                <span className={styles.span}>{caption}</span>
              </figcaption>
            </div>
          </figure>
        );
      })}
    </section>
  );
}

export default Figures;