import React from 'react';
import styles from './Header.module.scss';
import ExternalLink from './ExternalLink';

function Header({ href, topText, topTextColor, bottomText, bottomTextColor }) {
  return (
    <header className={styles.header}>
      <h1 className={styles.h1}>
        <ExternalLink href={href}>
          <span
            className={styles.span}
            style={{ color: topTextColor }}
          >{topText}</span>
          <span
            className={styles.span}
            style={{ color: bottomTextColor }}
          >{bottomText}</span>
          <img
            src="/assets/arrow.svg"
            alt=""
            className={styles.arrow}
          />
        </ExternalLink>
      </h1>
    </header>
  )
}

export default Header;