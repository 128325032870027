import getGeolocation from './getGeolocation';

async function getEndpointLocalData() {
  const { 
    country_data: {
      country_code: countryCode
    },
    state_data: {
      state_code: stateAbbreviation
    }
  } = await getGeolocation();
  
  return {
    endpoint: countryCode === 'US' ? (
      `${process.env.REACT_APP_S3_BUCKET_HOST}/data/states/${stateAbbreviation.toLowerCase()}.json`
    ) : '',
    state: stateAbbreviation.toUpperCase()
  };
}

export default getEndpointLocalData;