import React from 'react';

export const ExternalLinkParam = React.createContext({});

export class ExternalLinkParamProvider extends React.Component {

  params = {}

  determineParams({ icid, cid }) {
    if (icid) { this.params.key = 'icid'; this.params.value = icid }
    if (cid) { this.params.key = 'cid'; this.params.value = cid }
    return this.params
  }

  buildWebUrl = ({ key, value, href }) => {
    let url;

    if (key && value) {
      url = new URL(href);
      url.searchParams.set(key, value);
    }

    return url || href;
  }

  buildAppUrl = (url) => `nbcnews://story/inapp?url=${encodeURIComponent(url)}`


  constructUrl = (href) => {
    if (!href) return null;
    const { isShownInApp } = this.props.value;
    const url = this.buildWebUrl({ ...this.params, href});

    if (isShownInApp && href.includes('nbcnews.com')) {
      return this.buildAppUrl(url)
    }

    return url;

  }

  render() {
    return (
      <ExternalLinkParam.Provider value={{ params: this.determineParams(this.props.value), constructUrl: this.constructUrl, values: {...this.props.value} }}>
        {this.props.children}
      </ExternalLinkParam.Provider>
    );
  }
}

