import React from 'react';
import Figures from './Figures.js';
import useFetchLocations from '../hooks/useFetchLocations';

function FiguresHeader({ national, local, cases, deaths }) {
  const { response, error } = useFetchLocations(national, local);

  if (!response || error) {
    return null;
  }

  const { 
    national: {
      death: nationalDeaths, 
      cases: nationalCases 
    },
    local: {
      death: localDeaths, 
      cases: localCases,
      state
    }
  } = response;

  const caption = `Confirmed ${cases ? 'cases' : 'deaths'} ${national ? 'U.S.' : state} `;
  return (
    <>
      {(local && cases) && <Figures figures={[
        {
          "header": caption,
          "number": localCases
        }
      ]}/>}
      {(local && deaths) && <Figures figures={[
        {
          "header": caption,
          "number": localDeaths
        }
      ]}/>}
      {(national && cases) && <Figures figures={[
        {
          "header": caption,
          "number": nationalCases
        }
      ]}/>}
      {(national && deaths) && <Figures figures={[
        {
          "header": caption,
          "number": nationalDeaths
        }
      ]}/>}
    </>
  )
}

export default FiguresHeader;