import React from 'react';
import styles from './Figures2.module.scss';
import ExternalLink from './ExternalLink';

function Figures2({ figuresHref, figures }) {
  return (
    <section className={styles.section}>
      <ExternalLink href={figuresHref}>
        <table className={styles.table}>
          <tbody>
            {figures.map(({ number, numberColor, caption }, i) => {
              const hasNumericalData = typeof number === 'number';
              return (
                <tr key={i + number + caption} className={styles.figure}>
                  <td className={styles.number} style={{ color: numberColor }}>
                    {hasNumericalData ? number.toLocaleString() : <span className={styles.placeholder}>•••</span>}
                  </td>
                  <td className={styles.figcaption} >
                    {hasNumericalData ? caption : ''}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ExternalLink>
    </section>
  );
}

export default Figures2;