import React from 'react';
import * as pym from 'pym.js';
import styles from './MarqueeDemoPage.module.scss';
import MarqueeEmbedCode from './MarqueeEmbedCode';

class MarqueeDemoPage extends React.Component {
  constructor(props) {
    super(props);
    this.instantiatePymIframe = this.instantiatePymIframe.bind(this);
    this.baseUrl = window.location.origin.includes('localhost')
      ? 'http://localhost:3000'
      : (process.env.STORYBOOK_REACT_APP_S3_BUCKET_HOST || process.env.REACT_APP_S3_BUCKET_HOST);

    this.state = {
      url: this.getUrl(props),
      ramenUrl: this.getRamenUrl(props)
    }
  }

  componentDidMount() {
    this.instantiatePymIframe();
  }

  componentDidUpdate(prevProps, prevState) {
    const newUrl = this.getUrl(this.props)
    const newRamenUrl = this.getRamenUrl(this.props)
    if (newUrl !== prevState.url && newRamenUrl !== prevState.ramenUrl ) {
      this.setState({ url: newUrl, ramenUrl: newRamenUrl })
      this.instantiatePymIframe();
    }
  }

  pruneObj = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.pruneObj(obj[key]);
      else if (obj[key] === undefined) delete obj[key];
    });
    return obj;
  };

  getObj = (obj, componentKey, variant, location, context) => {
    if (obj && componentKey && obj[componentKey]) {
      if (variant && location && context) {
        return obj[componentKey][variant][location][context];
      } else if (variant && location) {
        return obj[componentKey][variant][location];
      } else if (variant) {
        return obj[componentKey][variant];
      } else {
        console.warn(`Component variant name is required. (e.g., basic => ?map=basic)`)
        return null;
      }
    }
    return '';
  }

  getCustomParams = (components, componentKey, variant, location, context) => {
    const options = this.getObj(components, componentKey, variant, location, context)

    if (!options) return '';

    let paramString = '[';
    paramString += Object.keys(options).map(key => {
      return `${key}=${options[key]}`;
    })

    paramString += ']';

    return paramString;
  }

  constructUrlParams = (props) => {
    const { marquee = {}, componentSettings = {}} = props;
    const prunedComponents = this.pruneObj(componentSettings)

    const params = Object.keys(marquee).map(key => {
      const { componentKey, variations = '' } = marquee[key];
      const [variant, location, context] = variations.split('.');
      const customizedParams = this.getCustomParams(prunedComponents, componentKey, variant, location, context);
      return `${componentKey}=${variations}${customizedParams}`;
    }).join('&')

    return params;
  }

  getRamenUrl = (props) => {
    const params = this.constructUrlParams(props)
    return `${this.baseUrl}/ramen-classes_pa0~mv0/?${params}`;
  }

  getUrl = (props) => {
    const params = this.constructUrlParams(props)
    return `${this.baseUrl}/?${params}`;
  }

  instantiatePymIframe = () => {
    new pym.Parent('pym-parent', this.state.url);
  }

  render() {
    return (
      <>
        <div id="pym-parent" className={styles.demo} />
        <MarqueeEmbedCode url={this.state.url} ramenUrl={this.state.ramenUrl} />
      </>
    )
  };
}

export default MarqueeDemoPage;