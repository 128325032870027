import React from 'react';
import Figures from './Figures.js';

function FiguresPercent() {
  return (
    <Figures figures={[
      {
        "header": "Confirmed cases",
        "number": 21787,
        "percent": "50",
        "caption": "since yesterday"
      }
    ]}/>
  )
}

export default FiguresPercent;