import React from 'react';
import styles from './Tease.module.scss';
import ExternalLink from './ExternalLink';

function Tease({ eyebrowText, eyebrowHref, headlineText, headlineHref }) {
  const isVisuallyComplete = eyebrowText && headlineText;

  return (
    <section className={`${styles.tease} ${isVisuallyComplete ? '' : styles.hide}`}>
      {eyebrowText && (
        <div className={styles.eyebrow} data-testid="eyebrow">
          <ExternalLink href={eyebrowHref || headlineHref}>{eyebrowText}</ExternalLink>
        </div>
      )}

      {headlineHref && headlineText && (
        <ExternalLink href={headlineHref} data-testid="headline">
          <h3 className={styles.headline}>
            {headlineText}
          </h3>
        </ExternalLink>
      )}
    </section>
  );
}

export default Tease;
