import React from 'react';
import Header from './Header.js';

function HeaderBasic({ ...overrides }) {
  return (
    <Header
      href="https://www.nbcnews.com/health/coronavirus"
      topText="CORONAVIRUS"
      topTextColor="inherit"
      bottomText="LIVE UPDATES"
      bottomTextColor="inherit"
      {...overrides}
    />
  );
}

export default HeaderBasic;