import React from 'react';
import Tease from './Tease';
import useFetch from '../hooks/useFetch';

function TeaseLatest({ ...overrides }) {
  const { response, error } = useFetch(`${process.env.REACT_APP_S3_BUCKET_HOST}/data/bento/latest.json`)

  if (error) {
    return null;
  }

  const [ topArticle ] = response || [];
  const{ title: headlineText, link: headlineHref } = topArticle || {};

  return (
    <Tease
      eyebrowText="Latest News"
      headlineText={headlineText}
      headlineHref={headlineHref}
      { ...overrides }
    />
  );
}

export default TeaseLatest;