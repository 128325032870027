import React from 'react';
import Figures2 from './Figures2.js';
import useFetchLocations from '../hooks/useFetchLocations';

function FiguresDouble({ 
  local, 
  national, 
  cases, 
  deaths,
  figuresHref = "https://www.nbcnews.com/health/health-news/coronavirus-u-s-map-where-virus-has-been-confirmed-across-n1124546"
}) {  
  const { response, error } = useFetchLocations(national, local);

  if (error) {
    return null;
  }

  const shouldUsePlaceholderData = !response;
  const data = shouldUsePlaceholderData ? {
    national: {
      death: null, 
      cases: null
    },
    local: {
      death: null, 
      cases: null,
      state: ' '
    }
  } : response;

  const { 
    national: {
      death: nationalDeaths, 
      cases: nationalCases 
    },
    local: {
      death: localDeaths, 
      cases: localCases,
      state
    }
  } = data;

  return (
    <>
      {(national && !local) && <Figures2 figuresHref={figuresHref} figures={[
        {
          "number": nationalCases,
          "caption": 'U.S. CASES'
        },
        {
          "number": nationalDeaths,
          "caption": 'U.S. DEATHS'
        }
      ]}/>}

      {(!national && local) && <Figures2 figuresHref={figuresHref} figures={[
        {
          "number": localCases ? localCases : nationalCases, 
          "caption": localCases ? `${state} CASES` : 'U.S. CASES'
        },
        {
          "number": localDeaths ? localDeaths : nationalDeaths,
          "caption": localDeaths ? `${state} DEATHS` : 'U.S. DEATHS'
        }
      ]}/>}

      {(national && local && !deaths && cases) && <Figures2 figuresHref={figuresHref} figures={[
        {
          "number": nationalCases,
          "caption": 'U.S. CASES'
        },
        {
          "number": localCases ? localCases : nationalDeaths,
          "caption": localCases ? `${state} CASES` : 'U.S. DEATHS'
        }
      ]}/>}

      {(national && local && deaths && !cases) && <Figures2 figuresHref={figuresHref} figures={[
        {
          "number": localDeaths ? nationalDeaths : nationalCases,
          "caption": localDeaths ? 'U.S. DEATHS' : 'U.S. CASES' 
        },
        {
          "number": localDeaths ? localDeaths : nationalDeaths,
          "caption": localDeaths ? `${state} DEATHS` : `U.S. CASES` 
        }
      ]}/>}
    </>
  );
}

export default FiguresDouble;