import React, { Fragment, useEffect, useState } from 'react';
import styles from './MarqueePage.module.scss';
import useURLSearchParams from '../hooks/useURLSearchParams';
import nbcnewsBreakpoints from '@nbcnews/design-tokens/tokens/nbcnews/breakpoints.json';
import * as pym from 'pym.js';
import debounce from 'lodash.debounce';
import { ExternalLinkParamProvider } from '../context/externalLinkParamContext';

import HeaderBasic from './HeaderBasic';

import Divider from './Divider';

import FiguresDouble from './FiguresDouble';
import FiguresHeader from './FiguresHeader';
import FiguresPercent from './FiguresPercent';
import FiguresBasic from './FiguresBasic';
import FiguresUnemployment from './FiguresUnemployment';

import MapBasic from './MapBasic'
import MapCaption from './MapCaption';

import FooterBasic from './FooterBasic';
import StocksBasic from './StocksBasic';
import TeaseLatest from './TeaseLatest';
import TeaseLocal from './TeaseLocal';
import Ticker from './Ticker';

import globalMarqueeConfigParams from '../utils/getGlobalMarqueeParams';

// Typically there are only four breakpoints, but the marquee requires
// more flexibility so we have added one more
// $breakpoint-xl: 1240px; $breakpoint-lg: 1000px; $breakpoint-md: 758px; $breakpoint-sm: 375px;
const breakpoints = {
  ...nbcnewsBreakpoints,

  ms: '500px' // ms = medium/small (used in articles)
};

function MarqueePage() {
  const globalConfig = {};
  const marqueeItemsConfig = [];
  const urlSearchParams = useURLSearchParams() || [];
  // Should change this to a reducer
  urlSearchParams.forEach(item => {
    const [ key, value ] = item;
    if (globalMarqueeConfigParams.includes(key)) {
      globalConfig[key] = value;
      return;
    }
    marqueeItemsConfig.push(item);
  })

  const [ breakpointMatches, setBreakpointMatches ] = useState({})

  useEffect(() => {
    function determineBreakpointMatches() {
      const breakpointMatches = {};

      Object.keys(breakpoints).forEach(key => {
        const breakpoint = breakpoints[key];

        breakpointMatches[key] = window.matchMedia(`(min-width: ${breakpoint})`).matches;
      });

      setBreakpointMatches(breakpointMatches)
    }

    determineBreakpointMatches();

    const debouncedDetermineBreakpointMatches = debounce(determineBreakpointMatches, 125);
    window.addEventListener('resize', debouncedDetermineBreakpointMatches);

    return () => window.removeEventListener('resize', debouncedDetermineBreakpointMatches);
  }, []);

  useEffect(() => {
    new pym.Child({ polling: 500 });
  });

  function lookupComponent(componentKey, variant, location, context, params) {

    const componentDictionary = {
      header: {
        get basic() { return <HeaderBasic {...params} /> }
      },
      divider: {
        get basic() { return <Divider {...params} /> },
        get dashed() { return <Divider dashed {...params} /> }
      },
      map: {
        basic: {
          local: {
            get cases() { return <MapBasic local cases {...params} /> },
            get deaths() { return <MapBasic local deaths {...params} /> }
          },
          national: {
            get cases() { return <MapBasic national cases {...params} /> },
            get deaths() { return <MapBasic national deaths {...params} /> }
          }
        },
        caption: {
          local: {
            get cases() { return <MapCaption local cases {...params} /> },
            get deaths() { return <MapCaption local deaths {...params} /> }
          },
          national: {
            get cases() { return <MapCaption national cases {...params} /> },
            get deaths() { return <MapCaption national deaths {...params} /> }
          }
        }
      },
      figures: {
        basic: {
          local: {
            get cases() { return <FiguresBasic local cases /> },
            get deaths() { return <FiguresBasic local deaths   /> }
          },
          national: {
            get cases() { return <FiguresBasic national cases /> },
            get deaths() { return <FiguresBasic national deaths   /> }
          }
        },
        double: {
        get local() { return <FiguresDouble local cases deaths/> },
        get national() { return <FiguresDouble national cases deaths/> },
          both: {
            get cases() { return <FiguresDouble national local cases/> },
            get deaths() { return <FiguresDouble national local deaths /> }
          }
        },
        header: {
          local: {
            get cases() { return <FiguresHeader local cases {...params} /> },
            get deaths() { return <FiguresHeader local deaths  {...params}  /> }
          },
          national: {
            get cases() { return <FiguresHeader national cases {...params} /> },
            get deaths() { return <FiguresHeader national deaths  {...params}  /> }
          }
        },
        percent: {
          local: {
            get cases() { return <FiguresPercent local cases {...params} /> },
            get deaths() { return <FiguresPercent local deaths  {...params}  /> }
          },
          national: {
            get cases() { return <FiguresPercent national cases {...params} /> },
            get deaths() { return <FiguresPercent national deaths {...params}  /> }
          }
        },
        get unemployment() { return <FiguresUnemployment {...params}  /> }
      },
      stocks: {
        get basic() { return <StocksBasic /> }
      },
      tease: {
        get latest() { return <TeaseLatest {...params} /> },
        get local() { return <TeaseLocal {...params}  /> }
      },
      footer: {
        get basic() { return <FooterBasic {...params}  /> }
      }
    };

    if (componentDictionary[componentKey]) {
      if (variant && location && context) {
        return componentDictionary[componentKey][variant][location][context];
      } else if (variant && location) {
        return componentDictionary[componentKey][variant][location];
      } else if (variant) {
        return componentDictionary[componentKey][variant];
      } else {
        console.warn(`Component variant name is required. (e.g., basic => ?map=basic)`)
        return null;
      }
    }
  }

  const getComponents = (compontsArray) => {
    return compontsArray.map(([componentKey, variantValue], i) => {
      let inferredProps = {
        icid: globalConfig.icid,
        cid: globalConfig.cid,
      };
      const componentWithProps = variantValue.split('[');
      const [ variations, props] = componentWithProps;
      const [variant, location, context] = variations.split('.');

      if (props) {
        const propsArray = props.replace('[', '').replace(']', '').split(',');
        propsArray.forEach(item => {
          const [key, value] = item.split('=');
          inferredProps[key] = decodeURIComponent(value);
        })
      }

      return (
        <Fragment key={componentKey + variantValue + i}>
          {lookupComponent(componentKey, variant, location, context, inferredProps)}
        </Fragment>
      )
    })
  }

  const getSliceForTicker = (tickerStartPoint, tickerEndPoint, items) => {
    const beforeTicker = items.slice(0, tickerStartPoint);
    const inTicker = items.slice(tickerStartPoint, tickerEndPoint).filter(([componentKey, ]) => componentKey !== 'divider');
    const afterTicker = items.slice(tickerEndPoint, items.length);

    return {
      beforeTicker,
      inTicker,
      afterTicker,
    }
  }

  const getMarkupForBreakpoint = ({ beforeTicker, inTicker, afterTicker }) => (
    <>
      {getComponents(beforeTicker)}
      <Ticker>
        {getComponents(inTicker)}
      </Ticker>
      {getComponents(afterTicker)}
    </>
  )

  const getClassesFromUrl = () => {
    let classes = '';

    if (globalConfig.classes) {
      const valuesList = globalConfig.classes.split(',');
      valuesList.forEach(val => classes += `${styles[val]} `)
    }

    return classes;
  }

  const conditionalClasses = getClassesFromUrl();



  return (
    <ExternalLinkParamProvider value={{ ...globalConfig }}>
      <aside className={styles.aside}>
        {/* SM Breakpoint */}
        {(
          !breakpointMatches.ms &&
          !breakpointMatches.md &&
          !breakpointMatches.lg &&
          !breakpointMatches.xl
        ) && (
          <div className={`${styles.marquee} ${styles.smOnly} ${conditionalClasses}`}>
            {getMarkupForBreakpoint(getSliceForTicker(5, marqueeItemsConfig.length, marqueeItemsConfig))}
          </div>
        )}

        {/* MS Breakpoint */}
        {(
          breakpointMatches.sm &&
          breakpointMatches.ms &&
          !breakpointMatches.md &&
          !breakpointMatches.lg &&
          !breakpointMatches.xl
        ) && (
          <div className={`${styles.marquee} ${styles.msOnly} ${conditionalClasses}`}>
            {getMarkupForBreakpoint(getSliceForTicker(7, marqueeItemsConfig.length, marqueeItemsConfig))}
          </div>
        )}

        {/* MD Breakpoint */}
        {(
          breakpointMatches.sm &&
          breakpointMatches.ms &&
          breakpointMatches.md &&
          !breakpointMatches.lg &&
          !breakpointMatches.xl
        ) && (
          <div className={`${styles.marquee} ${styles.mdOnly} ${conditionalClasses}`}>
            {getMarkupForBreakpoint(getSliceForTicker(5, 10, marqueeItemsConfig))}
          </div>
        )}

        {/* LG Breakpoint */}
        {(
          breakpointMatches.sm &&
          breakpointMatches.ms &&
          breakpointMatches.md &&
          breakpointMatches.lg &&
          !breakpointMatches.xl
        ) && (
          <div className={`${styles.marquee} ${styles.lgOnly} ${conditionalClasses}`}>
            {getMarkupForBreakpoint(getSliceForTicker(7, 10, marqueeItemsConfig))}
          </div>
        )}

        {/* XL Breakpoint */}
        {(
          breakpointMatches.sm &&
          breakpointMatches.ms &&
          breakpointMatches.md &&
          breakpointMatches.lg &&
          breakpointMatches.xl
        ) && (
          <div className={`${styles.marquee} ${styles.xlOnly} ${conditionalClasses}`}>
            {getMarkupForBreakpoint(getSliceForTicker(0, 0, marqueeItemsConfig))}
          </div>
        )}
      </aside>
    </ExternalLinkParamProvider>
  );
}

export default MarqueePage;