import React, { useState } from 'react';
import router from '../router';

import Store from './Store';

function App() {
  const [ store, setStore ] = useState(null);
  const initialStore = {};

  if (!store && initialStore) {
    setStore(initialStore);
  } else if (!store) {
    return null;
  }

  return (
    <div id="App">
      <Store.Provider value={{ ...store, setStore }}>
        {router}
      </Store.Provider>
    </div>
  );
}

export default App;
