import React from 'react';
import styles from './MarqueeEmbedCode.module.scss';

function MarqueeEmbedCode({ url, ramenUrl }) {

  const buildEmbedScript = (url) => {
    const random = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
    const embedId = `nbc-marquee-service-pym-parent-${random}`;
    return `<script src="https://nodeassets.nbcnews.com/cdnassets/pym/1.3.2/pym.min.js"></script><style>#${embedId} iframe {width: 1px;min-width: 100%}</style><div id="${embedId}"></div><script>var pymParent = new pym.Parent("${embedId}", "${url}", {title:''});</script>`
  }

  const embedCodeVariations = [
    {
      headline: 'Marquee Code',
      code: `${url}&icid=cv_marquee`,
      id: 'marquee'
    },
    {
      headline: 'Curator Embed Code',
      details: 'With borders',
      code: `${url}${'&classes=borders&icid=cv_marquee'}`,
      id: 'embed-pkg'
    },
    {
      headline: 'Curator Embed Code',
      details: 'With borders and no embed padding',
      code: `${ramenUrl}${'&classes=borders&icid=cv_marquee'}`,
      id: 'embed-pkg-no-padding'
    },
    {
      headline: 'Article Embed Code',
      details: 'With borders',
      code: buildEmbedScript(`${url}${'&classes=borders&icid=cv_marquee'}`),
      id: 'article'
    },
    {
      headline: 'In App Embed Code (chromeless)',
      details: 'With borders',
      code: `${url}&classes=borders&isShownInApp=true&icid=cv_marquee`,
      id: 'in-app'
    },
    {
      headline: 'Partner Code',
      details: 'No headline, no borders',
      code: `${url}${'&classes=isPartner&cid=cv_marquee'}`,
      id: 'partner'
    },
  ]

  const toggleCopyClass = (id) => {
    const preBlock = document.querySelector(`#pre-${id}`);
    const classes = preBlock.classList;
    if (!classes.contains('copied')) {
      classes.add('copied')
    }
    setTimeout(() => classes.remove('copied'), 1000);
  }


  const copyInput = function(id) {
    const copyText = document.querySelector(`#${id}`);
    copyText.select();
    document.execCommand("copy");
    toggleCopyClass(id);
  }

  return (
    <>
      {embedCodeVariations.map(variation => {
        const { headline, details, code, id } = variation;
        return (
          <>
            <h4 className={styles.headline}>{headline}</h4>
            <p className={styles.p}>{details}</p>
            <div className={styles.codeWrapper}>
              <button className={styles.button} onClick={() => copyInput(id)} for={id} id={`copy-${id}`}>Copy</button>
              <pre className={styles.pre} id={`pre-${id}`}>
                <code>{code}</code>
              </pre>
            </div>
            <input id={id} className={styles.hidden}  value={code} />
          </>
        )
      })}
    </>
  );
}

export default MarqueeEmbedCode;
