import React from 'react';
import styles from './Footer.module.scss';
import ExternalLink from './ExternalLink'


function Footer({ topText, topTextHref, bottomText, bottomTextHref }) {

  return (
    <footer className={styles.footer}>
      <ExternalLink href={topTextHref} className={styles.headline} data-testid="topText">
        {topText}
      </ExternalLink>

      <ExternalLink href={bottomTextHref} className={styles.liveBlog} data-testid="bottomText">
        {bottomText}
      </ExternalLink>
    </footer>
  );
}

export default Footer;
