import React from 'react';
import Figures from './Figures2.js';
import useFetchLocations from '../hooks/useFetchLocations';

function FiguresBasic({ national, local, cases }) {
  const { response, error } = useFetchLocations(national, local);

  if (!response || error) {
    return null;
  }

  const { 
    national: {
      death: nationalDeaths, 
      cases: nationalCases 
    },
    local: {
      death: localDeaths, 
      cases: localCases,
      state
    }
  } = response;

  const caption = `${national ? 'U.S.' : state} ${cases ? 'CASES' : 'DEATHS'}`;
  return (
    <>
      {(national && !local) && <Figures figures={[
        {
          "number": cases ? nationalCases : nationalDeaths,
          "caption": caption
        }
      ]}/>}

      {(!national && local) && <Figures figures={[
        {
          "number": cases ? localCases : localDeaths,
          "caption": caption
        }
      ]}/>}
    </>
  )
}

export default FiguresBasic;