import React, { useState, useEffect } from 'react';
import Tease from './Tease';
import getGeolocation from '../utils/getGeolocation';

function TeaseLocal({ ...overrides }) {
  const [ error, setError ] = useState(false);
  const [ feed, setFeed ] = useState({});

  useEffect(() => {
    async function getLocalNews() {
      try {
        const {
          country_data: {
            country_code: countryCode
          },
          state_data: {
            state,
            state_code: stateCode
          },
          city_data: {
            city
          }
        } = await getGeolocation();

        if (countryCode === 'US') {
          const stateAbbreviation = stateCode.toLowerCase();
          const cityName = city.replace(/ /g, '-').toLowerCase();

          const localizedResponse = await fetch(`${process.env.REACT_APP_S3_BUCKET_HOST}/data/feeds/${stateAbbreviation}/${cityName}.json`);

          if (localizedResponse.status === 200) {
            const { title: headlineText, link: headlineHref } = await localizedResponse.json();

            return setFeed({
              eyebrowText: `${state.toUpperCase()} NEWS`,
              headlineText,
              headlineHref,
              state
            });
          }
        }

        // Default to national
        const nationalResponse = await fetch(`${process.env.REACT_APP_S3_BUCKET_HOST}/data/bento/latest.json`);
        const nationalFeed = await nationalResponse.json();

        // Use second top post to prevent identical posts showing up
        // when next to TeaseLatest component
        const [, secondTopPost ] = nationalFeed || [];
        const { title: headlineText, link: headlineHref } = secondTopPost || {};

        return setFeed({ headlineText, headlineHref })
      } catch (e) {
        console.log(e);
        setError(true);
      }
    }

    getLocalNews();
  }, []);

  if (error) {
    return null;
  }

  const {
    eyebrowText,
    headlineText,
    headlineHref
  } = feed;

  return (
    <Tease
      eyebrowText={eyebrowText || "Latest News"}
      headlineText={headlineText}
      headlineHref={headlineHref}
      {...overrides}
    />
  );
}

export default TeaseLocal;