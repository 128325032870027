import React from 'react';
import styles from './Map.module.scss';
import ExternalLink from './ExternalLink';

function Map({ src, alt, callToAction, href }) {
  return (
    <section className={styles.section}>
      <ExternalLink
        href={href}
        className={styles.a}
      >
        <img
          src={src}
          alt={alt}
          className={styles.img}
        />

        {callToAction && <div className={styles.callToAction}>{callToAction}</div>}
      </ExternalLink>
    </section>
  );
}

export default Map;