import React from 'react';
import Figures from './Figures.js';

function FiguresUnemployment() {
  return (
    <Figures figures={[
      {
        "header": "Unemployment",
        "number": 3283000,
        "numberColor": "#2A2A2A",
        "percent": "50",
        "caption": "since last week"
      }
    ]}/>
  )
}

export default FiguresUnemployment;