import React from 'react';
import Stocks from './Stocks.js';

function StocksBasic() {
  return (
    <Stocks
      stocks={[
        {
          "symbol": "S&P 500",
          "position": "24870.09",
          "percent": "-1.79",
          "href": "https://www.cnbc.com/quotes/?symbol=.DJI"
        },
        {
          "symbol": "DOW",
          "position": "24870.09",
          "percent": "0",
          "href": "https://www.cnbc.com/quotes/?symbol=.SPX"
        },
        {
          "symbol": "NASDAQ",
          "position": "24870.09",
          "percent": "+1.79",
          "href": "https://www.cnbc.com/quotes/?symbol=.IXIC"
        }
      ]}
    />
  )
}

export default StocksBasic;