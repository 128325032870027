import React, { useContext } from 'react';
import styles from './ExternalLink.module.scss';
import { ExternalLinkParam } from "../context/externalLinkParamContext";

// Default to target="top" for amp support
function ExternalLink({ href, target = '_top', children, ...attrs }) {
  const { constructUrl } = useContext(ExternalLinkParam);

  if (!href) return null;
  const url = (constructUrl && constructUrl(href)) || href;

  return (
    <a
      className={styles.a}
      href={url}
      target={target}
      rel="noopener noreferrer"
      data-testid="external-link"
      {...attrs}
    >
      {children}
    </a>
  );
}

export default ExternalLink;
